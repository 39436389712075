<template>
  <div class="wrap">
    <Sticky>
      <Navbar :title="$t('page.openRecord')" />
    </Sticky>
    <List
      @load="getList"
      v-model="loading"
      :finished="!hasMore"
      :finished-text="$t('Hint.notMore')"
      :loading-text="$t('Hint.loading')"
    >
      <div
        v-for="(item, index) in lists"
        :key="`item_${index}`"
        class="item_wrap"
      >
        <!-- 时间 -->
        <div class="result">
          <div class="title">{{ $t("open.openTime") }}</div>
          <div class="value">
            {{ $format(item.opentime) }}
          </div>
        </div>
        <!-- 期号 -->
        <div class="order_num">
          <div class="title">{{ $t("popup.orderId") }}</div>
          <div class="value">{{ item.expect }}</div>
        </div>
        <!-- 结果 -->
        <div class="result" v-if="type.indexOf('hl') === -1">
          <div class="title">{{ $t("open.result") }}</div>
          <div class="ball_wrap">
            <div
              class="ball"
              v-for="(item, index) in item.open.dwd"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="result" v-else>
          <div class="title">{{ $t("open.result") }}</div>
          <div class="value">
            <img
              v-for="(r_item, r_index) in item.open.dwd"
              :key="'result' + index + r_index"
              :src="require(`@/assets/icon/dice/${r_item}.png`)"
              alt=""
            />
          </div>
        </div>
        <!-- 和值 -->
        <div class="result">
          <div class="title">{{ $t("open.hz") }}</div>
          <div class="value">
            {{ item.open.zh.zh }}
          </div>
        </div>
        <!-- <div class="bottom">
          <div class="hz">
            <div class="title">{{ $t('open.hz') }}</div>
            <div class="value">{{ item.open.zh.zh }}</div>
          </div>
          <div class="time">
            <div class="title">{{ $t("open.openTime") }}</div>
            <div class="value">{{ $format(item.opentime) }}</div>
          </div>
        </div> -->
      </div>
    </List>
  </div>
</template>

<script>
import Navbar from "@/components/JNav";
import { Cell, List, Sticky } from "vant";
import { post } from "@/utils/newRequest";
export default {
  components: { Navbar, Cell, List, Sticky },
  data() {
    return {
      page: 0,
      loading: false,
      hasMore: true,
      lists: [],
      type: 0,
    };
  },
  created() {
    const { type } = this.$route.query;
    this.type = type;
  },
  methods: {
    async getList() {
      this.page++;
      try {
        const { lists, hasMorePages } = await post("/lottery/lists", {
          type: this.type,
          pagesize: 20,
          page: this.page,
        });
        const realList = lists.filter((item) => item.open.dwd);
        this.hasMore = hasMorePages;
        this.lists = [...this.lists, ...realList];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.page--;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
@comBG: linear-gradient(#093958, #022c47);
.wrap {
  background: var(--hallBg);
  min-height: 100vh;
  overflow: hidden;
}
.item_wrap {
  // background: #eee;
  // background: var(--bg2);
  background: linear-gradient(#093958, #022c47);
  margin-top: 12px;
  padding: 12px 20px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    &:nth-child(1) {
      margin-top: 0;
    }

    .title {
      // color: #999;
      color: var(--gameSizeColor);
      font-size: 14px;
    }
    .value {
      color: var(--btnsizeColor);
    }
  }

  .result {
    .value {
      display: flex;

      > img {
        width: 20px;
        height: 20px;
        margin-left: 12px;
      }
    }
    .ball_wrap {
      .ball {
        border-radius: 50%;
        width: calc(26rem / 16);
        line-height: calc(26rem / 16);
        margin: 1px;
        text-align: center;
        background: var(--theme);
        color: #000;
        display: inline-block;
        background-image: url("../../assets/icon/game_ball.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>